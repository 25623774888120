import React from 'react'
import PageLayout from '../components/PageLayout';
import HoverableLink from '../components/HoverableLink';
import styled from 'styled-components';
import { centerize, centerizeBody } from '../utils/commonCSS';

function About({className}) {
  return (
    <PageLayout title="About us" uppercaseTitle titleAnim>
      <div className={className}>
        <p className="slogan">
          <em>
            Emerging Young Artists (EYA) is a not-for-profit organization based in Toronto.
          </em>
        </p>
        <p>
          Our mission is to increase appreciation for emerging and underrepresented artists’ work through artistic development and public exhibition. We also endeavor to foster contemporary art practices that act as catalysts for inclusivity and diversity in the arts, cross-cultural dialogue, and critical engagement of contemporary contexts.
        </p>
        <p>
          In pursuit of our mission, we offer programs including curated art exhibitions that feature Canadian (and international) emerging artists from a wide range of artistic backgrounds. We showcase their work every year in professional art galleries located in vibrant areas of Toronto. In addition, we organize workshops, and concerts that cater to all levels of interest and professional skills of young artists. These initiatives aim to stimulate discourse within the community and encourage participation in the arts.
        </p>
        <p className="team-link">
          <HoverableLink text="Meet our team" to="/team"/>
        </p>
      </div>
    </PageLayout>
  )
}

export default styled(About)`
  .slogan, .team-link {
    ${centerize}
  }
  ${centerizeBody}
  ${HoverableLink} {
    font-size: 20px;
  }
`;
